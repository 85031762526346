import React from 'react';
import { Link } from 'gatsby';
import navbarStyles from './navbar.module.css';

const Navbar = () => {
  const navLinks = [
    { slug: '/subscribe/', navbarName: 'Subscribe' },
    { slug: '/about/', navbarName: 'About' },
    { slug: '/terms-of-service/', navbarName: 'Terms' },
  ].map(({ slug, navbarName }) => {
    return (
      <Link key={ slug } to={ slug }>
        { navbarName }
      </Link>
    );
  })

  return (
    <header>
      <nav className={navbarStyles.navbar}>
        <div className={navbarStyles.navbarContainer}>
          <div className={navbarStyles.brand}>
            <Link to="/"></Link>
          </div>
          <div className={navbarStyles.links}>
            {navLinks}
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Navbar;
