import React from 'react';
import Navbar from './navbar';
import layoutStyles from './layout.module.css';

const Layout = ({ children }) => {
  return (
    <div className={layoutStyles.layout}>
      <Navbar />
      <main>{children}</main>
      <footer>
        © {new Date().getFullYear()}, Spreadsheets Made Easy
      </footer>
    </div>
  );
};

export default Layout
